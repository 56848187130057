import * as React from 'react';
import { Box, Button, Container, Paper, Grid, Typography, TextField, MenuItem, Alert, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as moment from 'moment';
import { toast } from 'react-toastify';
import 'moment/locale/pt-br';

import PageDefault from '../../components/PageDefault';
import api from '../../services/api';
import apiDAM from '../../services/apiDAM';

import { streets } from '../../services/helper';

export default function GerarCobrancaMassiva() {
    const [rua, setRua] = React.useState('');
    // const [mes_ref, setMesRef] = React.useState(moment().format('MM'));
    const [mes_ref, setMesRef] = React.useState('12');
    const [ano_ref, setAnoRef] = React.useState(moment().format('YYYY'));
    const [exercicio, setExercicio] = React.useState(moment().format('YYYY'));

    const [imoveisIds, setImoveisIds] = React.useState([]);
    const [dialogMsg, setDialogMsg] = React.useState('');
    
    const [carregando, setCarregando] = React.useState(false);

    const [openDialog, setOpenDialog] = React.useState(false);

    const navigate = useNavigate();

    function handleBack() {
        navigate(-1);
    }

    function isValid() {
        
        if (rua.trim() === '') {
            toast.error('Rua inválida');
            return false;
        }
        if (mes_ref.trim() === '') {
            toast.error('Mês de Referência inválido');
            return false;
        }
        if (ano_ref.trim() === '') {
            toast.error('Ano de Referência inválido');
            return false;
        }
        if (exercicio.trim() === '') {
            toast.error('Exercício inválido');
            return false;
        }
        return true;
    }

    function gerarCobranca() {
        setOpenDialog(false);
        setCarregando(true);

        const dataPy = {
            'imoveis': imoveisIds,
            "exercicio": parseInt(exercicio),
            "mes_referencia": parseInt(mes_ref),
            "ano_referencia": parseInt(ano_ref)
        }
        
        apiDAM.post(`/cobranca-massiva?com-aviso=True`, dataPy, {
            headers: {
                'Content-Type': 'application/json',
                'keyword': process.env.REACT_APP_API_DAM_KEYWORD
            }
        })
        .then(res => {
            setCarregando(false);
            const blob = new Blob([res.data], {
                type: 'application/pdf'
            });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
        })
        .catch(err => {
            setCarregando(false);
            const message = err.response.data.message;
            toast.error(message);
        })
    }
        
    async function handleSubmit() {
        if (isValid()) {
            setCarregando(true);
            let page = 0
            let qtd = 1 // apenas para entrar no loop
            const size = 50
            const aux = []
            
            // TODO pendente de alteração na api para geracao por nome da rua

            /* eslint-disable */
            while (qtd > 0) {
                await api.get(`/contribuinte/imovel?page=${page}&size=${size}&rua=${rua}`)
                .then(res => {
                    qtd = res.data.content.length;
                    page = page + 1;
                    res.data.content.forEach(({ id }) => {
                        aux.push(id)
                    });
                })
                .catch(err => {
                    if (err.response) {
                        toast.error(err.response.data.message);
                    }
                });
            }
            /* eslint-enable */
            setImoveisIds(aux);
            setCarregando(false);

            if (aux.length === 0) {
                toast.error('Rua sem imóveis cadastrados');
                return;
            } else {
                setDialogMsg(`Deseja realmente gerar a cobrança para os ${aux.length} imóveis cadastrados na Rua ${rua}? *Essa ação é irreversível`);
                setOpenDialog(true);
            }
        }
    }

    return (
        <PageDefault carregando={carregando} title='Geração de DAM por Rua'>

            <Alert severity="warning" sx={{mt: 2}}>
                Ao gerar a cobrança dessa forma a multa e os juros são calculados automaticamente pelo sistema. Atualmente não está implementado,
                sendo definido como padrão em R$ 0,00
            </Alert>

            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>

                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <React.Fragment>
                        <Typography variant="h6" gutterBottom>
                            Dados da Cobrança
                        </Typography>
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="rua"
                                    name="rua"
                                    label="Rua"
                                    fullWidth
                                    select
                                    value={rua}
                                    onChange={e => setRua(e.target.value)}
                                >
                                    {streets.map(s => (
                                        <MenuItem value={s} key={s}>
                                            {s}
                                        </MenuItem>    
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="mes_ref"
                                    select
                                    fullWidth
                                    required
                                    disabled
                                    label="Mês Referência"
                                    value={mes_ref}
                                    onChange={e => setMesRef('12')}
                                >
                                    <MenuItem value='01'>01</MenuItem>
                                    <MenuItem value='02'>02</MenuItem>
                                    <MenuItem value='03'>03</MenuItem>
                                    <MenuItem value='04'>04</MenuItem>
                                    <MenuItem value='05'>05</MenuItem>
                                    <MenuItem value='06'>06</MenuItem>
                                    <MenuItem value='07'>07</MenuItem>
                                    <MenuItem value='08'>08</MenuItem>
                                    <MenuItem value='09'>09</MenuItem>
                                    <MenuItem value='10'>10</MenuItem>
                                    <MenuItem value='11'>11</MenuItem>
                                    <MenuItem value='12'>12</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="ano_ref"
                                    select
                                    fullWidth
                                    required
                                    label="Ano Referência"
                                    value={ano_ref}
                                    onChange={e => (
                                        setAnoRef(e.target.value)
                                    )}
                                >
                                    <MenuItem value='2022'>2022</MenuItem>
                                    <MenuItem value='2023'>2023</MenuItem>
                                    <MenuItem value='2024'>2024</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                            <TextField
                                    id="exercicio"
                                    select
                                    fullWidth
                                    required
                                    label="Exercício"
                                    value={exercicio}
                                    onChange={e => (
                                        setExercicio(e.target.value)
                                    )}
                                >
                                    <MenuItem value='2022'>2022</MenuItem>
                                    <MenuItem value='2023'>2023</MenuItem>
                                    <MenuItem value='2024'>2024</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                onClick={handleBack}
                                sx={{ mt: 3, ml: 1 }}>
                                Voltar
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                                sx={{ mt: 3, ml: 1 }}
                            >
                                Gerar DAM
                            </Button>
                        </Box>
                    </React.Fragment>
                </Paper>
            </Container>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirmação de Ação
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogMsg}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
                    <Button onClick={gerarCobranca} autoFocus>
                        Continuar
                    </Button>
                </DialogActions>
            </Dialog>
        </PageDefault>
    );
}